import { isNullOrUndefined, isValidDate, isValidString } from '@/utils/TypeGuards'

/**
 * How many milliseconds the user has to refresh the page to empty the cache.
 */
const thresholdLocalStorage: number = 800

/**
 * List of stores that should be kept.
 * @remarks Do not confuse localStorage keys with piniaStore names.
 * @remarks Removing this keys sets of emitters listening and triggering {@link authService.signinCallback} again resulting in an infinite loop, see {@link clearLocalStorageSafely} for further details.
 */
const keepStores: string[] = ['privilegeStore', 'legalEntityStore', 'versionStore']

/**
 * List of keys that have to be emptied.
 */
const forceKeys: string[] = ['lastRefresh']

/**
 * Checks if the cache should be emptied.
 * @returns `true` if the cache should be emptied = user refreshed twice within the threshold.
 */
function shouldEmptyCache(): boolean {
  const lastRefresh = localStorage.getItem('lastRefresh')
  console.log(new Date().getTime() - Number(lastRefresh))
  if (isNullOrUndefined(lastRefresh)) {
    // there was no previous refresh, first time visit
    return false
  }
  const lastRefreshDate: Date = new Date(Number(lastRefresh))
  if (!isValidDate(lastRefreshDate)) {
    // invalid date?
    console.error('Invalid date in local storage: ', lastRefresh)
    return false
  }
  const now: Date = new Date()
  const diff: number = now.getTime() - lastRefreshDate.getTime()

  return diff < thresholdLocalStorage
}

/**
 * Updates the last refresh in the local storage.
 */
function updateLastRefresh(): void {
  const now: Date = new Date()
  localStorage.setItem('lastRefresh', String(now.getTime()))
}

/**
 * Will only clear predesignated keys.
 * @remarks The application refreshes itself after filling some keys and to avoid an infinite loop, we only clear the keys we set.
 */
function clearLocalStorageSafely() {
  for (let i: number = 0; i < localStorage.length; i++) {
    // we can be sure that `i` is a valid index, hence as string
    const key: string = localStorage.key(i) as string
    if (
      forceKeys.includes(key) ||
      // key is a store and not in the keepStores list
      (key.endsWith('Store') && !keepStores.includes(key))
    ) {
      localStorage.removeItem(key)
    }
  }
}

/**
 * Main function. WIll empty the cache if the user refreshed twice within the threshold.
 * @modifies {@link localStorage}
 * @remarks Executes immediately upon script load (IIFE).
 */
;(function startupCacheManagement(): void {
  if (shouldEmptyCache()) {
    console.warn(`Emptying cache due to double refresh within ${thresholdLocalStorage / 1000} seconds.`)
    //localStorage.clear();
    clearLocalStorageSafely()
    location.reload()
  } else {
    updateLastRefresh()
  }
})()
