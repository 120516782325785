import { app, emitter } from '@/main'
import { i18n } from '@/i18n'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { capitalize, nameLocale } from '@/utils/various'
import { useI18nStore } from '@/store/I18nStore'
import { showError } from './axios'
import axios from 'axios'
import { apiURL, Languages } from '@/utils/environment'
import { ref } from 'vue'

export const defaultLanguage: Lang = 'de'

export type Lang = 'de' | 'fr' | 'it'

export type upLang = 'De' | 'Fr' | 'It'
export type isoLang = 'de-ch' | 'fr-ch' | 'it-ch'
export type nameKey = 'nameDe' | 'nameFr' | 'nameIt'
export type upNameKey = 'NameDe' | 'NameFr' | 'NameIt'

export type SelectedLang = {
  lang: Lang
  upLang: upLang
  nameKey: nameKey
  upNameKey: upNameKey
}

/** i represent a reactive object for the language selected by the user represented by the url
 * */
export const selectedLang = ref<SelectedLang>({
  lang: defaultLanguage,
  upLang: 'De',
  nameKey: 'nameDe',
  upNameKey: 'NameDe'
})

//forced reload after frontend ui, language change
export async function loadTranslations(lang: string, forced: boolean) {
  const i18nStore = useI18nStore()
  const storedTranslation = i18nStore.getTranslation(lang)
  if (!forced && storedTranslation) {
    try {
      console.log('loadTranslations translation detected')
      i18n.global.setLocaleMessage(lang, storedTranslation)
      i18n.global.locale.value = lang
    } catch (e) {
      console.log('loadTranslations i18n settings', e)
    }
    return storedTranslation
  }
  try {
    emitter.emit('setLanguageLoading', true)
    const result: any = await axios.get(apiURL + '/translations/language/' + lang, {
      params: { language: lang }
    })
    i18n.global.setLocaleMessage(lang, result.data)
    i18n.global.locale.value = lang
    i18nStore.setTranslation(lang, result.data)
    return result.data
  } catch (e) {
    console.log('loadTranslations', e)
    showError(e)
  } finally {
    emitter.emit('setLanguageLoading', false)
  }
}

export function showLanguageKeys() {
  const keys = Object.keys(i18n.global.getLocaleMessage(i18n.global.locale.value))
  keys.forEach((key) => {
    i18n.global.setLocaleMessage(i18n.global.locale.value, { key: key })
  })
}

/* i'm registered in Router beforeEach */
export async function routeInterceptor(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<any> {
  console.log('language routeInterceptor testing', to, from)
  if (to.name == 'oidcCallback') {
    //do not intercept oidcclient
    next()
    return
  }

  if (!Languages.includes(to.params.lang as string)) {
    next({
      name: to.name ?? 'home',
      params: { lang: defaultLanguage },
      query: to.query,
      hash: to.hash
    })
    return //only call next once
  }
  selectedLang.value.lang = to.params.lang as Lang
  selectedLang.value.upLang = capitalize(to.params.lang as string) as upLang
  selectedLang.value.nameKey = nameLocale(to.params.lang as string) as nameKey
  selectedLang.value.upNameKey = capitalize(nameLocale(to.params.lang as string)) as upNameKey

  app.axios.defaults.headers.common['Accept-Language'] = to.params.lang
  emitter.emit('setLocale', to.params.lang)

  await loadTranslations(to.params.lang as string, false)

  //legacy
  to.params.isoLangKey = (nameLocale(to.params.lang as string) + '-ch') as isoLang
  to.params.upLang = capitalize(to.params.lang as string) as upLang
  to.params.langKey = nameLocale(to.params.lang as string) as nameKey
  to.params.upLangKey = capitalize(nameLocale(to.params.lang as string)) as upNameKey
  next()
  return
}
