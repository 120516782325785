/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import '@/utils/localStorageUtils'
// Components
import App from './App.vue'
// Composables
import { createApp } from 'vue'
import routeHelper from './mixins/routeHelper'
// Plugins store router etc
import { registerPlugins } from '@/plugins'
import mitt from 'mitt'
// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
/* i18n wird im Router geladen wenn die Route zum ersten mal auf /de gepusht wird */
import { i18n } from '@/i18n'
import { configAxios } from './services/axios'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
//register toaster
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

//highcharts
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import HighchartsVue from 'highcharts-vue'

export const app = createApp(App)

app.mixin(routeHelper)

export function getApp() {
  return app
}

registerPlugins(app)

export const emitter = mitt()

app.use(i18n)
configAxios(axios)
app.use(VueAxios, axios)

app.component('VueDatePicker', VueDatePicker)
app.component('DbmDatePicker', DbmDatePicker)
app.mount('#app')

More(Highcharts)
app.use(HighchartsVue)

app.use(Toast)

emitter.emit('initialized', 'bueno')
